import WhiteLogo from "../images/earthshare_logo.svg";
import stripe from "../images/stripe.svg";
import { ReactComponent as Background } from '../images/onboarding-background.svg';
import { ReactComponent as PageBackground} from "../images/earthshare_dashboard.svg";
import { createTheme } from "@mui/material";

export const earthShareTheme = createTheme({
    palette: {
        primary: {
            light: '#DEFAFF',
            main: '#4bafc4'
        }
    },
    brightImgUrl: WhiteLogo,
    darkImgUrl : stripe,
    onboardingImage: Background,
    dashboardImage: PageBackground,
})