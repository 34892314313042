import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from "react-router-dom";

const columns: GridColDef[] = [
  { field: 'external_id', headerName: 'Order ID', flex: 0.16 },
  { field: 'slug', headerName: 'Transaction ID', flex: 0.16 },
  { field: 'status', headerName: 'Status', flex: 0.16 },
  { field: 'cost', headerName: 'Offset Cost', flex: 0.1 },
  { field: 'carbon', headerName: 'Carbon (kg)', flex: 0.1 },
  { 
    field: 'certificate', 
    headerName: 'Project funds were directed to', 
    flex: 0.16, 
    renderCell: (params) => (
      <a href={params.row.certificate === '' ? "https://my.carbon.click/RM-CARBONCLICK/projects/NZCF_ARAWERA_1" : params.row.certificate} target="_blank">View Project</a>
    )
  },
];


const Orders = () => {
  const [rows, setRows] = useState([]);

  const getOrders = async () => {
    const request = await fetch(`${process.env.REACT_APP_API_URL}/api/orders/`, { credentials: 'include' })
    const orders = await request.json()
    setRows(orders);
  }

  useEffect(() => {
    getOrders();
  }, [])

  return (
    <>
      <Grid
        height={"90%"}
        p={2}
        display={"flex"}
        mt={2}
        flexDirection={"column"}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Grid>
    </>
  );
};

export default Orders;
