

import { ThemeContext } from "@emotion/react";
import { useTheme } from "@mui/material";



export interface ImageProps {
    darkMode: boolean
}

export const LogoComponent = ({ darkMode }: ImageProps) => {
    const theme = useTheme();
    const url = darkMode ? theme.darkImgUrl : theme.brightImgUrl
    return <img className="logo" src={url} />
}