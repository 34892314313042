import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import Background from '../../images/welcome.svg';
import Logo from '../../images/logo.svg';
import {
    Grid,
    Box,
    LinearProgress,
    useTheme
} from '@mui/material'

const backgroundStyling = {
    backgroundImage: `url(${Background})`
}

const Welcome = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { search } = useLocation();
    const [max, setMax] = useState(90)
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        fetch(`${process.env.REACT_APP_API_URL}/register/shopify/${search}`, {
            credentials: 'include'
        })
        .then(r => r.json()
        .then(j => {
            if(j.success) {
                setMax(100);
            }
        }))
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === max && max === 100) {
                    navigate('/onboarding/register')
                }
                const diff = Math.random() * 10;
                return Math.min(oldProgress + diff, max);
            });
        }, 500);

        return () => {
            clearInterval(timer);
        };
    }, [max])

    return (
        <Grid className="welcome" container sx={backgroundStyling}>
            <Box sx={{ width: 'fit-content' }}>
                <img src={theme.brightImgUrl} />
                <LinearProgress className="loader" variant="determinate" value={progress} sx={{ mt: 5 }} />
            </Box>
        </Grid>
    )
};

export default Welcome