import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { Layout } from "../../components/OnboardingLayout"
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js';
import LoadingButton from '@mui/lab/LoadingButton';
import { Token } from '@stripe/stripe-js';
import { Typography } from '@mui/material';

const Payment = () => {
  const [isLoading, setIsLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const navigate = useNavigate();

  const stripeTokenHandler = async (token: Token) => {
    const paymentData = {token: token.id};

    const request = await fetch(`${process.env.REACT_APP_API_URL}/api/shop/`, {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(paymentData)
    });
    const response = await request.json();
    if(response.ok) {
      navigate('/onboarding/widget')
    } else {
      alert(response.message)
      setIsLoading(false);
    }
  }

  const submit = async () => {
    setIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);
    if (card) {
      const result = await stripe.createToken(card);
      if (result.error) {
        // Show error to your customer.
        setIsLoading(false);
        alert(result.error.message);
      } else {
        // Send the token to your server.
        // This function does not exist yet; we will define it in the next step.
        stripeTokenHandler(result.token);
      }
    }

  }

  const setShopify = async () => {
    const request = await fetch(`${process.env.REACT_APP_API_URL}/api/payments/`, {
      credentials: 'include',
    });
    const response = await request.json();
    window.location.href = response.url
  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <Layout
      title='Payment details'
      subTitle="Funds for offsetting will be collected from your customers by you. Your card will then be charged for credits to use for purchasing carbon offsets."
      className='widget-designer'
      stage={3}
    >

      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <Typography variant='caption' color={'gray'} textAlign='left'>Prefer not to use a credit card? <Typography onClick={() => setShopify()} variant='caption' color={'primary'} sx={{ cursor: 'pointer' }}>Click here</Typography></Typography>
      <LoadingButton loading={isLoading} onClick={() => submit()} variant='contained' sx={{ margin: '15px 0px!important' }}>Submit Payment</LoadingButton>
    </Layout>
  )
}

export default Payment;