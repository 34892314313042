import WhiteLogo from "../images/logo.svg";
import stripe from "../images/stripe.svg";
import { ReactComponent as Background } from '../images/onboarding-background.svg';
import { ReactComponent as PageBackground} from "../images/welcome.svg";
import { createTheme } from "@mui/material";

export const carbonclickTheme = createTheme({
    palette: {
        primary: {
            light: '#EAF9EC',
            main: '#4C9B5A'
        }
    },
    brightImgUrl: WhiteLogo,
    darkImgUrl : stripe,
    onboardingImage: Background,
    dashboardImage: PageBackground,
})