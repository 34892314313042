import {
    Grid,
    Box,
    Stepper,
    StepLabel,
    StepConnector,
    stepConnectorClasses,
    styled,
    Button,
    Step,
    useTheme,
} from '@mui/material';
import Logo from '../images/logo.svg';
import { ReactComponent as Background } from '../images/onboarding-background.svg';

export interface LayoutProps {
    stage: number
    title: string
    subTitle: string
    contentTitle?: string
    className?: string
    children?: JSX.Element | JSX.Element[]
}

export const OnboardingSteps = [
    'Account Creation',
    'Shipping Information',
    'Behaviour',
    'Payment',
    'Widget',
];

export const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: theme.palette.primary.main,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        borderColor: 'white',
        borderTopWidth: 3,
        borderRadius: 1,
    },
}));


export const Layout = ({ stage, title, subTitle, contentTitle, className, children }: LayoutProps) => {
    const theme = useTheme();
    return (
        <Grid className='layout' container>
            <Grid item md={6}>
                <div className='background-image'>
                    <theme.onboardingImage />
                </div>
                <Box sx={{ p: 6, pl: 8 }}>
                    <img src={theme.brightImgUrl} />
                    <Stepper activeStep={stage} alternativeLabel sx={{ mt: 7 }} connector={<QontoConnector />}>
                        {OnboardingSteps.map((label) => (
                            <Step sx={{
                                '& .MuiStepLabel-iconContainer .Mui-active': {
                                    '&.MuiSvgIcon-root': {
                                        color: 'primary.main'
                                    }
                                },
                                '& .MuiStepLabel-iconContainer &.Mui-disabled': {
                                    '&.MuiSvgIcon-root': {
                                        color: 'primary.main'
                                    }
                                },
                            }} key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <p className='title'>{title}</p>
                    <p className='subtitle'>{subTitle}</p>
                </Box>
            </Grid>
            <Grid className='content' item md={6} direction='column'>
                {contentTitle && <p className='box-title'>
                    {contentTitle}
                </p>}
                <Box className={`box ${className ? className : ''}`} sx={{ mt: 5 }}>
                    {children}
                </Box>
            </Grid>
        </Grid>
    )
}